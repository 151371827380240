import React from 'react';
import './Modal.css';

export default function Modal({ item, closeModal }) {
  const isMobile = window.innerWidth <= 768;

  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()} style={{ maxHeight: '80vh', overflow: 'auto', marginBottom: '40px' }}>
        <div style={isMobile ? { width: '100%', height: '200px' } : { width: '100%', height: '300px', objectFit: "cover", marginBottom: '20px' }}>
          <img
            src={item.image}
            style={{
              width: '100%',
              height: isMobile ? '200px' : '260px',
              borderRadius: '10px',
              objectFit: 'cover'
            }}
            alt="Imagem do passeio"
          />
        </div>
        {isMobile ? <h3>{item.nome}</h3> : <h2 style={{ paddingTop: '20px' }}>{item.nome}</h2>}
        <div className="descricao-texto" style={{ padding: '25px', alignItems: 'center' }}>
          {!!item.info_fechado &&
            <h4 style={{ color: 'black', fontSize: '17px', fontFamily: "Raleway", textAlign: 'center', fontStyle: "italic" }}>{item.info_fechado}</h4>
          }

          <h4 style={{ color: 'black', fontSize: '17px', fontFamily: "Raleway", textAlign: 'center' }}>{item.detalhes_completos}</h4>


          {!!item.detalhes_completos2 &&
            <h4 style={{ color: 'black', fontSize: '17px', fontFamily: "Raleway", textAlign: 'center' }}>{item.detalhes_completos2}</h4>
          }
        </div>
        { !item.no_info &&
          <>
            <div className="descricao-texto" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '90%', gap: '20px', alignItems: 'center', marginTop: '15px' }}>
              <h3 style={{ width: '100%', backgroundColor: '#9eb9c9', borderRadius: '20px', padding: '6px', color: 'white', textAlign: 'center', fontSize: '13px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='item-passeio-caracteristica'>
                Saída: À partir do hotel
              </h3>
              <h3 style={{ width: '100%', backgroundColor: '#9eb9c9', borderRadius: '20px', padding: '6px', color: 'white', textAlign: 'center', fontSize: '13px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='item-passeio-caracteristica'>
                Chegada: No hotel ou outro ponto em Paris de sua preferência
              </h3>
            </div>


            <div className="descricao-texto" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '90%', gap: '20px' }}>
              <div className="descricao-texto" style={{ padding: '20px' }}>
                <h3 style={{ width: '100%', borderRadius: '20px', padding: '6px', color: 'black', textAlign: 'center', fontSize: '13px', fontWeight: 'bold' }} className='item-passeio-caracteristica'>
                  Não está incluso:
                </h3>
                <ul style={{ width: '100%', borderRadius: '20px', padding: '6px', color: 'black', textAlign: 'left', fontSize: '13px', fontWeight: 'bold', listStyleType: 'none' }} className='item-passeio-caracteristica'>
                  <li>• Ingressos</li>
                  <li>• Almoço</li>
                  <li>• Guia Turístico Conferencista</li>
                  <li>• Tickets para o transporte público.</li>
                </ul>
              </div>
            </div>
          </>
        }


        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '40px' }}>
          <button
            style={{
              backgroundColor: '#142C4C',
              color: 'white',
              borderRadius: '20px',
              padding: '10px 20px',
              fontSize: '17px',
              fontFamily: 'Raleway',
              cursor: 'pointer',
              border: '2px solid white', // Adiciona uma borda sólida
              transition: 'background-color 0.3s, color 0.3s' // Transição suave para hover
            }}
            onClick={() => window.open(`https://wa.me//33749038393?text=Olá, gostaria de saber as informações sobre o passeio: ${item.nome ? item.nome : ''}.`, '_blank')}
            onMouseOver={(e) => {
              e.target.style.backgroundColor = '#4a4e54';
              e.target.style.color = '#e6e6e6';
            }}
            onMouseOut={(e) => {
              e.target.style.backgroundColor = '#1a2a3e';
              e.target.style.color = 'white';
            }}
          >
            CONSULTAR VALOR
          </button>
        </div>
      </div>
      <div className="modal-footer" style={isMobile ? { width: '90%' } : { width: '40%' }}>
        <button className='buttonFecharModal' onClick={closeModal}>FECHAR</button>
      </div>
    </div>
  );
}
